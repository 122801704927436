<template>
  <v-container fluid fill-height style='height: 90vh'>
    <v-row no-gutters align='center' justify='center'>
      <v-card
        flat
        width='450'
      >
        <v-container fluid justify='center' align='center' class='h-100'>
          <v-card-title>{{$t('NEW_USER')}}</v-card-title>
          <v-card-subtitle>{{$t('PROFILE_DATA')}}</v-card-subtitle>

          <v-row no-gutters>
            <v-card-text class='pt-0'>
              <v-form ref='form' v-model='formIsValid' lazy-validation>
                <v-text-field
                  v-model='user.name'
                  :rules='[rules.required]'
                  clearable
                >
                  <template #label>
                    {{ $t('NAME') }} <span style='color: red'>*</span>
                  </template>
                </v-text-field>
                <v-text-field
                  v-model='user.email'
                  :rules='[rules.required]'
                  :hint='$t("EMAIL_TIP_NEW_USER")'
                  clearable
                >
                  <template #label>
                    {{ $t('EMAIL') }} <span style='color: red'>*</span>
                  </template>
                </v-text-field>
                <v-text-field
                  v-model='user.cpf'
                  v-mask:bind='cpfMask'
                  clearable
                  :rules='[rules.required, rules.validCpf]'
                >
                  <template #label>
                    {{ $t('CPF') }} <span style='color: red'>*</span>
                  </template>
                </v-text-field>

                <v-select
                  v-if='getUser && getUser.account.role.isSuperAdmin'
                  v-model='user.role'
                  :items='roleList'
                  item-value='id'
                  item-text='description'
                  clearable
                  :rules='[rules.required]'
                >
                  <template #label>
                    {{ $t('ROLE') }} <span style='color: red'>*</span>
                  </template>
                </v-select>

                <v-select
                  v-if='getUser && (getUser.account.role.isSuperAdmin)'
                  v-model='user.customer'
                  item-value='id'
                  item-text='name'
                  clearable
                  :rules='[rules.required]'
                  :items='customerList'
                >
                  <template #label>
                    {{ $t('CUSTOMER') }} <span style='color: red'>*</span>
                  </template>
                </v-select>

                <v-select
                  v-model='user.group'
                  item-text='name'
                  item-value='id'
                  hint='Selecione um grupo para o usuário'
                  persistent-hint
                  multiple
                  chips
                  clearable
                  :rules='[rules.arrayRequired]'
                  :items='groups'
                  :disabled='getUser && (getUser.account.role.isSuperAdmin) && !user.customer'
                >
                  <template #label>
                    Grupo de permissão <span style='color: red'>*</span>
                  </template>
                </v-select>
              </v-form>

              <v-btn block color='secondary' class='mt-8' @click='save'>
                {{$t('SAVE')}}
              </v-btn>
            </v-card-text>
          </v-row>
        </v-container>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
  import axios from 'axios';
  import { mapGetters } from 'vuex';
  import { mask } from 'vue-the-mask';
  import { cpfValidate } from '../../util/validation/cpf';

  export default {
    name: 'UsersDetails',
    directives: {
      mask: mask,
    },
    data: function () {
      return {
        cpfMask: '###.###.###-##',
        formIsValid: false,
        showPassword: false,
        rules: {
          validCpf: (value) => !value || (value && cpfValidate(value, true)) || this.$t('INVALID_CPF'),
          required: (value) => !!value || this.$t('REQUIRED'),
          passwordMatch: (value) => (value === this.user.password) || this.$t('PASSWORDS_ENTERED_DO_NOT_MATCH'),
          arrayRequired: (value) => Array.isArray(value) && value.length > 0 || this.$t('REQUIRED'),
        },
        roleList: [],
        customerList: [],
        user: {
          name: undefined,
          email: undefined,
          cpf: undefined,
          role: undefined,
          customer: undefined,
          group: undefined,
        },
        groups: [],
      };
    },
    computed: {
      ...mapGetters('auth', [
        'getUser',
      ]),
    },
    watch: {
      'user.customer' (newCustomerId) {
        if (!newCustomerId) {
          this.groups = [];
        } else {
          this.getGroupsByCustomerId(newCustomerId);
        }
      },
    },
    mounted: function () {
      this.getRoleList();
      const isSuperAdmin = this.getUser && this.getUser.account && this.getUser.account.role && this.getUser.account.role.isSuperAdmin;
      if (isSuperAdmin) {
        this.getCustomerList();
      }
      if (!isSuperAdmin) {
        this.getGroupsByCustomerId(this.getUser.account.customerId);
      }
    },
    methods: {
      togglePasswordVisibility: function () {
        this.showPassword = !this.showPassword;
      },
      getRoleList: async function () {
        try {
          const { data } = await axios({
            url: '/account-get-roles',
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: 'GET',
            responseType: 'json',
            withCredentials: true,
          });

          this.roleList = data && data.list;
        } catch (e) {
          console.log(e); /* eslint-disable-line no-console */
        }
      },
      getCustomerList: async function () {
        try {
          const { data } = await axios({
            url: '/customer',
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: 'GET',
            responseType: 'json',
            withCredentials: true,
          });

          this.customerList = data && data.list;
        } catch (e) {
          console.log(e); /* eslint-disable-line no-console */
        }
      },
      getGroupsByCustomerId: async function (customerId) {
        try {
          const { data } = await axios({
            url: `/permission-group/${customerId}`,
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: 'GET',
            responseType: 'json',
            withCredentials: true,
          });

          this.groups = data && data.list;
        } catch (e) {
          console.log(e); /* eslint-disable-line no-console */
        }
      },
      save: async function () {
        const formValidation = this.$refs.form.validate();

        if (!formValidation) {
          return;
        }

        try {
          await axios({
            url: '/account-sign-up',
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: 'POST',
            responseType: 'json',
            withCredentials: true,
            data: {
              name: this.user.name,
              email: this.user.email,
              cpf: this.user.cpf,
              roleId: this.user.role,
              customerId: this.user.customer,
              permissionGroupIds: this.user.group,
            },
          });
          this.$emit('new-user');
          this.$emit('close');
        } catch (e) {
          console.log(e); /* eslint-disable-line no-console */
        }
      },
    },
  };
</script>
